@import "../../assets/variables.scss";
@import "../../assets/mixins.scss";
@import "../../assets/breakpoints.scss";

.about {
    @include pageSetup;
    color: white;

    .container {
        @include container(column, flex-start, center);
        opacity: 0;
        transition: 0.4s ease;

        &.show {
            opacity: 1;
        }
    }

    .about-content-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        .about-info {
            flex-basis: 60%;
            padding: 1rem;

            @include md {
                flex-basis: 100%;
            }

            h1.page-title {
                width: 100%;
                font-weight: 200;
                text-align: center;
                margin: 1rem;
                span {
                    color: springgreen;
                }
            }

            p {
                line-height: 1.75rem;
                font-weight: 200;
                font-size: 1.2rem;
                margin-bottom: 1rem;
            }
        }

        .about-tech {
            flex-basis: 40%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
            gap: 1rem 1.5rem;
            padding: 1rem;
            @include md {
                flex-basis: 100%;
                margin: 1rem 0 2rem 0;
            }

            h2 {
                display: block;
                width: 100%;
                text-align: center;
                font-weight: 200;
                padding-bottom: 0.5rem;
                position: relative;
                margin-top: 1.5rem;

                &::after {
                    content: '';
                    width: 50%;
                    height: 2px;
                    background-color: springgreen;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);

                }
            }


            .icon-div {
               display: flex;
               justify-content: center;
               align-items: center;
               width: 25%;
               height: 4rem;
               position: relative;

               &::before {
                content: '';
                width: 5rem;
                height: 5rem;
                border-radius: 50%;
                position: absolute;
                transform: scale(0);
                transform-origin: bottom left 1rem;
                transition: 0.3s ease;
               }

               &::after {
                position: absolute;
                bottom: -2rem;
                opacity: 0;
                transition: 0.3s ease;
               }

               &:hover::before {
                   transform: scale(1);
               }

               &:hover::after {
                   opacity: 1;
               }

               &.icon-div-0 {
                    &::before {
                        background-color: #FF9E36;
                    }
                    &::after {
                        content: 'HTML';
                    }

                }
               &.icon-div-1 {
                    &::before {
                        background-color: #3596FF;
                    }
                    &::after {
                            content: 'CSS';
                    }
                }
               &.icon-div-2 {
                    &::before {
                        background-color: #FFDA36;
                    }
                    &::after {
                        content: 'JavaScript';
                    }
                }
               &.icon-div-3 {
                    &::before {
                        background-color: #17E4EB;
                    }
                    &::after {
                        content: 'React.js';
                    }
               }
               &.icon-div-4 {
                    &::before {
                        background-color: #FF8BE8;
                    }
                    &::after {
                        content: 'Sass';
                    }
                }
               &.icon-div-5 {
                    &::before {
                        background-color: #B652FE;
                    }
                    &::after {
                        content: 'Bootstrap';
                    }
                }
               &.icon-div-6 {
                    &::before {
                         background-color: #FF3902;
                    }
                    &::after {
                        content: 'Git';
                    }
                }
               &.icon-div-7 {
                    &::before {
                        background-color: #71007B;
                    }
                    &::after {
                        content: 'GitHub';
                    }
                }
               &.icon-div-8 {
                    &::before {
                         background-color: #FF2020;
                    }
                    &::after {
                        content: 'npm';
                    }
                }
            }

            .about-icon {
                width: 3rem;
                height: auto;
                position: relative;
            }
        }
    }
}