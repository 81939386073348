@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';
@import "../../assets/breakpoints.scss";

.footer {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $dark;

    .container {
        color: white;
        @include container(row, space-between, center);
        padding: 1rem;
        h1 {
            flex-basis: 30%;
            font-weight: 200;
            font-size: 1.3rem;

            @include sm {
                font-size: 1.2rem;
            }
            @include xs {
                font-size: 1rem;
            }
            span {
                color: springgreen;
            }
        }

        p {
            flex-basis: 40%;
            font-size: 0.75rem;
            text-align: center;

            @include sm {
                flex-basis: 60%;
            }
        }

        .social-links {
            flex-basis: 30%;
            display: flex;
            justify-content: flex-end;
            text-align: right;

            @include sm {
                display: none;
            }
        }
        a {
            margin-right: 5%;
            &:last-child {
                margin-right: 0;
            }
            svg {
                display: inline-block;
                color: white;
                width: 1.5rem;
                height: 1.5rem;
                padding: 0.1rem;
                transition: 0.2s ease;


                &:hover {
                    color: springgreen;
                }
            }
        }
    }
}