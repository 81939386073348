@import "../../assets/variables.scss";
@import "../../assets/mixins.scss";
@import "../../assets/breakpoints.scss";


.home {
    @include pageSetup;

    .container {
        @include container(row, center, center);
        color: white;
        gap: 1rem;
        opacity: 0;
        transition: 0.4s ease;

        &.show {
            opacity: 1;
        }

        @include md {
            background-image: url('../../assets/images/home-img.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            padding: 0;
            background-position-x: 60%;
        }
        @include sm {
            background-position-x: 60%;
        }
        .home-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-basis: 40%;
            height: 100%;

            @include md {
                flex-basis: 100%;
                align-items: flex-start;
                background: linear-gradient(to right, rgba(0,0,0,0.7), rgba(0, 0, 0, 0));
            }

            @include sm {
                background: rgba(0, 0, 0, 0.6);
            }
            
            .home-info-content {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                align-items: center;
                justify-content: center;
            

                h1 {
                    font-weight: 400;
                    @include lg {
                        text-align: center;
                    }
                span {
                    color: springgreen
                }
                }

                p {
                    font-size: 1.2rem;
                    margin: 1rem 0 5rem;
                    text-align: center;
                    line-height: 2rem;

                    @include lg {
                        margin: 1rem 0 3rem;
                    }
                }

                .cta {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    color: white;
                    text-decoration: none;
                    padding: 1rem;
                    border: 2px solid white;
                    border-radius: 30px;
                    width: 80%;
                    max-width: 250px;
                    font-size: 1.2rem;
                    margin: 0 auto;
                    transition: 0.2s ease;
                    position: relative;
                    z-index: 0;

                    @include lg {
                        width: 75%;
                        font-size: 1rem;
                    }

                    @include md {
                        width: 100%;
                        background-color: springgreen;
                        border: 0;
                        color: $dark;
                        font-size: 1.2rem;
                    }

                    p {
                        font-size: 1rem;
                        padding: 0;
                        margin: 0;
                        display: inline-block;
                        transition: transform 0.4s ease;
                        font-size: 1.2rem;
                    }
                    

                    .chevron {
                        position: absolute;
                        top: 50%;
                        right: 1rem;
                        opacity: 0;
                        transform: translateY(-50%) scale(0);
                        transition: 0.4s ease;
                        color: $dark;
                        font-size: 1.2rem;
                    }

                    

                    &:hover {
                        color: $dark;
                        border: 2px solid springgreen;
                        background-color: springgreen;

                        p {
                            transform: translateX(-1rem);
                        }

                        .chevron {
                            opacity: 1;
                            transform: translateY(-50%) scale(1);
                        }
                    }

                    
                    
                }

                @include md {
                    width: 50%;
                    text-align: center;
                    justify-content: center;
                    align-items: flex-start;
                    padding-left: 1rem;
                }

                @include sm {
                    width: 100%;
                    align-items: center;
                    padding: 0 1rem;
                }
            }
        
        }

        .home-img {
            flex-basis: 50%;
            border-radius: 20px;
            overflow: hidden;
            transition: 0.2s ease;
            box-shadow: -6px 5px 15px 0px rgba(0,0,0,0.5);
            height: 80%;
            max-height: 500px;
            @include md {
                display: none
            }

            &:hover {
                box-shadow: -6px 5px 15px 0px rgba(0,255,127,0.5);
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}