@import "../../assets/mixins.scss";
@import "../../assets/variables.scss";
@import "../../assets/breakpoints.scss";

    // "xs": 0,
    // "sm": 480px,
    // "md": 720px,
    // "lg": 960px,
    // "xl": 1200px


.nav-bar {
    width: 100%;
    height: 3.5rem;
    background-color: #1c1c1c;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    .container {
        width: 100%;
        max-width: 1300px;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .logo {
            color: white;
            text-align: left;
            display: flex;
            align-items: center;
            h1 {
                font-weight: 200;
                @include md {
                    font-size: 1.25rem;
                }

                @include sm {
                    font-size: 1.5rem;
                }
            }
            span {
                color: springgreen;
            }
        }

        .nav-links {
            width: 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include md {
                width: 60%;
            }

            @include sm {
                position: fixed;
                top: 3.5rem;
                right: 0;
                flex-direction: column;
                width: 100%;
                justify-content: space-evenly;
                height: calc(100vh - 3.5rem);
                padding-bottom: 10%;
                background-color: rgba(28, 28, 28, 0.9);
                transform: translateX(100vw);
                transition: 0.3s ease;

                &.open {
                    transform: translateX(0);
                }
            }
        }
        

        a.nav-link {
            text-decoration: none;
            font-size: 1.2rem;
            position: relative;
            color: white;
            transition: 0.3s ease;
            padding: 0.25rem;

            @include md {
                font-size: 1rem;
            }

            @include sm {
                font-size: 2rem;
            }


            &.active {
                color: springgreen;

                &:hover::after {
                    transform: translateX(-50%) scale(1);
                    background-color: springgreen;
                }
            }

            &::after {
                content: '';
                background-color: white;
                width: 100%;
                height: 2px;
                position: absolute;
                bottom: -0.25rem;
                left: 50%;
                transform: translateX(-50%) scale(0);
                transform-origin: left;
                transition: 0.3s ease;
                border-radius: 3px;
            }

            &:hover::after {
                transform: translateX(-50%) scale(1);
            }
        }
        .social-links {
            display: none;
            @include sm {
                display: flex;
                justify-content: space-evenly;
                width: 70%;
                text-align: center;
                margin-top: 1rem;
            }

            a {
                color: white;
                font-size: 1.5rem;
            }
        }

        
    }

    .hamburger {
        display: none;
        position: relative;

        @include sm {
            display: block;
            width: 2rem;
            height: 2rem;
        }

        .top-line, .middle-line, .bottom-line {
            content: '';
            width: 100%;
            height: 2px;
            position: absolute;
            background-color: white;
            transition: 0.3s ease;
        }

        .top-line {
            top: 25%;
            left: 50%;
            transform: translate(-50%, -25%) rotate(0deg);
        }

        .middle-line {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 1;
        }

        .bottom-line {
            top: 75%;
            left: 50%;
            transform: translate(-50%, -75%) rotate(0deg);
        }

        &.open {
            .top-line {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg)
            }

            .middle-line {
                opacity: 0;
                transform: scale(0);
            }

            .bottom-line {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-45deg)
            }
        }
    }

    
}