@import "../../assets/variables.scss";
@import "../../assets/breakpoints.scss";

.portfolio-card {
    color: white;
    width: 25%;
    min-width: 375px;
    height: auto;
    min-height: 20rem; 
    border-radius: 30px;
    padding-bottom: 3rem;
    box-shadow: 5px 5px 15px 5px #000000;
    transition: 0.2s ease;
    

    @include sm {
        box-shadow: 5px 5px 15px -1px rgba(0,255,127,0.5);
    }

    &:hover {
        box-shadow: 5px 5px 15px -1px rgba(0,255,127,0.5);
        transform: scale(1.025);
    }
    .container {
        padding: 0;
    }

    .img-wrapper {
        width: 100%;
        height: 50%;
        overflow: hidden;
        border-radius: 30px 30px 0 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .content {
        padding: 0 2rem;
        display: flex;
        flex-direction: column;
    
        h1 {
            margin: 1rem 0;
            font-weight: 200;
            font-size: 1.5rem;
            text-align: center;

        }

        .tech {
            font-weight: 200;
            text-align: center;
            color: springgreen;
        }

        .bullet-points {
            font-weight: 400;
            margin-top: 1rem;
        }
    }


    .buttons {
        margin: 1rem;
        display: flex;
        justify-content: center;
        gap: 1rem;

        a {
            text-decoration: none;
            padding: 0.5rem;
            border-radius: 20px;
            transition: 0.2s ease;
        }

        .live-link {
            color: springgreen;
            border: 2px solid springgreen;
            &:hover {
                color: $dark;
                background-color: springgreen;
            }
        }

        .code-link {
            color: springgreen;
            border: 2px solid springgreen;
            &:hover {
                color: $dark;
                background-color: springgreen;
            }
        }
    }

    
}